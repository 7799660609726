if(document.readyState == 'complete') lazyLoad();
document.addEventListener('DOMContentLoaded', lazyLoad());

function lazyLoad() {
  const lazyImages = [].slice.call(document.querySelectorAll('img.lazy, iframe.lazy, div.lazy'));

  if ('IntersectionObserver' in window) {
    const lazyImageObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;

          if (lazyImage.hasAttribute("data-src")) {
            lazyImage.src = lazyImage.dataset.src;
          } else if (lazyImage.hasAttribute("data-bg")) {
            var retina = window.matchMedia("(-webkit-min-device-pixel-ratio: 2)");
            if ((lazyImage.hasAttribute("data-bgretina")) && (retina.matches)) {
              lazyImage.style.backgroundImage = "url(" + lazyImage.dataset.bgretina + ")";
            }
            else {
              lazyImage.style.backgroundImage = "url(" + lazyImage.dataset.bg + ")";
            }
          } else {
            var s, sources;
            sources = lazyImage.querySelectorAll("source");
            for (s = 0; s < sources.length; s++) {
              sources[s].srcset = sources[s].dataset.srcset;
            }
          }
          lazyImage.classList.remove('lazy');
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    lazyImages.forEach(function (lazyImage) {
      lazyImageObserver.observe(lazyImage);
    });
  } else {
    console.log('Get a modern browser: https://browsehappy.com/');
    document.querySelectorAll('.lazy').forEach((element) => {
      element.classList.remove('lazy');
      element.src = element.dataset.src;
    });
  }
}