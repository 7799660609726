let calendarButtons = [...document.querySelectorAll('select[name="calendarType"]')];

if(calendarButtons.length > 0) {
  calendarButtons.forEach((select) => {
    select.addEventListener('change', (opt) => {
      console.log(this, opt)
      select.nextSibling.href = opt.target.value;
      select.nextSibling.innerHTML = `Add to ${opt.target.options[opt.target.selectedIndex].innerHTML} Calendar`
    })
  })
}
